import axios from "axios";
const defaultHeaders = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
  "x-auth-token": "",
};
export function apiClient({
  url,
  data,
  method = "POST",
  headers = {},
  noHeaders,
  ...rest
}) {
  return new Promise(async (resolve, reject) => {
    const authToken = localStorage.getItem("token");
    defaultHeaders["x-auth-token"] = authToken || "";
    axios({
      method,
      url,
      headers: {
        ...(noHeaders ? {} : defaultHeaders),
        ...headers,
      },
      data,
      ...rest,
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        if(err?.response?.data?.statusCode === 401){
          localStorage.clear();
        }
        reject(err);
      });
  });
}
