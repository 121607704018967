import { apiClient } from "../APIClient";
import { API_URL } from "../APIConfig";

export const loginAdmin = (data) => {
  return new Promise((resolve, reject) => {
    apiClient({
      method: "POST",
      url: `${API_URL.SESSION_LOGIN}`,
      data,
    })
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const logoutAdmin = (data) => {
    return new Promise((resolve, reject) => {
      apiClient({
        method: "GET",
        url: `${API_URL.SESSION_LOGOUT}`,
        data,
      })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  