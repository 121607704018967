const BASE_URL = process.env.REACT_APP_API_URL;

export const API_URL = {
  CUSTOMER_LIST: `${BASE_URL}/customer/list`,
  CUSTOMER_STATUS_UPDATE: `${BASE_URL}/customer/status-update`,
  CUSTOMER_DETAILS_LIST: `${BASE_URL}/order/details-by-customer-and-orderId`,
  ORDER_BY_CUSTOMER: `${BASE_URL}/order/list-by-customer`,
  BUSINESS_USER_LIST: `${BASE_URL}/business-user/list`,
  BUSINESS_USER_DETAILS_LIST: `${BASE_URL}/business-user/details`,
  ORDER_LIST: `${BASE_URL}/order/list`,
  SESSION_LOGIN: `${BASE_URL}/session/login`,
  SESSION_LOGOUT: `${BASE_URL}/session/logout`,
  DASHBOARD_LIST: `${BASE_URL}/dashboard/stats`,
  BUSINESS_USER_STATUS_UPDATE: `${BASE_URL}/business-user/status-update`,
  BUSINESS_USER_DETAILS_STATUS_UPDATE: `${BASE_URL}/business-user/business/status-update`,
};
