import { Suspense, React, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./Routes";
import PrivateRoute from "./PrivateRoute";
import LoaderContext from "../context/LoaderContext";
import Loader from "../commas/Loader";

const AppRoutes = () => {
  const { loader } = useContext(LoaderContext);
  return (
    <>
      {loader && <Loader />}
      <Suspense fallback={<Loader />}>
        <Routes>
          {routes &&
            routes.length > 0 &&
            routes.map((route, index) => (
              <Route
                path={route?.path}
                key={"route_" + index}
                element={
                  route.isPrivate ? (
                    <>
                      <PrivateRoute>
                        <route.element />
                      </PrivateRoute>
                    </>
                  ) : (
                    <>
                      <route.element />
                    </>
                  )
                }
              />
            ))}
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
