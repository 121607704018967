import { message } from "antd";

export const useNotification = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const notification = (massage, type) => {
    messageApi.open({
      type: type,
      content: massage,
    });
  };

  return {
    notification,
    contextHolder,
  };
};
