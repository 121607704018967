import { lazy } from "react";
const login = lazy(() => import("../pages/Login"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const BusinessUsers = lazy(() => import("../pages/BusinessUsers"));
const Customer = lazy(() => import("../pages/Customer"));
const Order = lazy(() => import("../pages/Order"));
const BusinessDetails = lazy(() => import("../pages/BusinessDetails"));
const CustomerDetails = lazy(() => import("../pages/CustomerDetails"));
const OrderDetails = lazy(() => import("../pages/OrderDetails"));
export const routes = [
  {
    element: login,
    path: "/login",
    isPrivate: false,
  },
  {
    element: Dashboard,
    path: "/dashboard",
    isPrivate: true,
  },
  {
    element: OrderDetails,
    path: "/dashboard/:id",
    isPrivate: true,
  },
  {
    element: BusinessUsers,
    path: "/business-users",
    isPrivate: true,
  },
  {
    element: Customer,
    path: "/customer",
    isPrivate: true,
  },
  {
    element: Order,
    path: "/order",
    isPrivate: true,
  },
  {
    element: OrderDetails,
    path: "/order/:id",
    isPrivate: true,
  },
  {
    element: BusinessDetails,
    path: "/business-users/:key",
    isPrivate: true,
  },
  {
    element: CustomerDetails,
    path: "/customer/:key",
    isPrivate: true,
  },
  {
    element: OrderDetails,
    path: "/customer/:key/:id",
    isPrivate: true,
  },
];
