import { ConfigProvider } from "antd";
import React, { useState } from "react";
import { Provider } from "react-redux";
import AppRoutes from "./routes";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import AuthContext from "./context/AuthContext";
import LoaderContext from "./context/LoaderContext";

const App = () => {
  const [authUser, setAuthUser] = useState(
    localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser"))
      : null
  );
  const [loader, setLoader] = useState(false);
  return (
    <BrowserRouter>
      <LoaderContext.Provider value={{ loader, setLoader  }}>
        <AuthContext.Provider value={{ authUser, setAuthUser }}>
          <Provider store={store}>
            <ConfigProvider>
              <AppRoutes />
            </ConfigProvider>
          </Provider>
        </AuthContext.Provider>
      </LoaderContext.Provider>
    </BrowserRouter>
  );
};
export default App;
