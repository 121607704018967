import React from "react";
import { Navigate } from "react-router";
import Layout from "../layout/layout";

const PrivateRoute = ({ isLayout, children }) => {
  return localStorage.getItem("authUser") && localStorage.getItem("token") ? (
    <Layout>{children}</Layout>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
